<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-3xl uppercase tracking-widest mt-5 text-center animate-slide-down-fade-in01s">Account Disabled</h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p>Your account is currently disabled, please contact <a href="mailto:support@metawin.com">support@metawin.com</a> for further information.</p>
      </div>
      <ButtonButton
        type="button"
        class="block w-full mt-12"
        @click.prevent.stop="close()"
      >
        Close
      </ButtonButton>
    </div>
  </article>
</template>

<script>
export default defineComponent({
  name: 'ModalErrorContentUserDisabled',
  emits: [
    'close',
  ],
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>
