<template>
  <article class="animate-fade-in">
    <div class="text-center block">
      <h1 class="text-2xl tracking-wide font-semibold mb-2 text-center animate-slide-down-fade-in01s">
        Deposit Required
      </h1>
      <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
        <p v-html="errorModalMessage"/>
      </div>
      <ButtonButton
        type="button"
        class="block w-full mt-8"
        @click.prevent.stop="swap"
      >
        Swap Now
      </ButtonButton>
    </div>
  </article>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const emits = defineEmits([
  'close',
]);

const uiStore = useUiStore();
const {
  errorModalData,
  showWalletModal,
  errorModalMessage,
} = storeToRefs(uiStore);

function close() {
  emits('close');
  errorModalData.value = null;
  errorModalMessage.value = null;
}
function swap() {
  close();
  showWalletModal.value = true;
}
</script>
